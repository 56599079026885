import { motion, AnimatePresence } from 'framer-motion';

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <AnimatePresence mode='wait'> 
      {isOpen && (
        <>
          <motion.div
            className="modal-backdrop fade show"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5}}
            exit={{ opacity: 0 }}
            onClick={onClose}
          >
          
        </motion.div>
        <motion.div
          className="modal fade show"
          initial={{ y: "-100vh" }}
          animate={{ y: 0, opacity: 1, display: `block` }}
          exit={{ y: "100vh" }}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Modal;