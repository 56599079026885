import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLicenses } from "../redux/slices/licensesSlice";
import { fetchAppInfo } from "../redux/slices/appInfoSlice";
import { fetchCustomers } from "../redux/slices/customersSlice";
import { fetchProducts } from "../redux/slices/productsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUsers } from "../redux/slices/usersSlice";


const Loader = ({children}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const licensesLoadingStatus = useSelector(state => state.licenses.licensesLoadingStatus);
    const appInfoLoadingStatus = useSelector(state => state.appInfo.appInfoLoadingStatus)
    const customersLoadingStatus = useSelector(state => state.customers.customersLoadingStatus);
    const productsLoadingStatus = useSelector(state => state.products.productsLoadingStatus);
    const usersLoadingStatus = useSelector(state => state.users.usersLoadingStatus);
    const user = useSelector(state => state.users.user);
    useEffect(() => {
        if (Object.keys(user).length !== 0 || pathname.includes('/license-checkout/') ||  pathname.includes('/notification')) {
            dispatch(fetchLicenses());
            dispatch(fetchAppInfo());
            dispatch(fetchCustomers());
            dispatch(fetchProducts());
            dispatch(fetchUsers());
        } else {
            dispatch(fetchUsers());
            navigate('/login');
        }
    }, [user]);

    if ((licensesLoadingStatus === 'idle' &&
        appInfoLoadingStatus  === 'idle' &&
        customersLoadingStatus === 'idle' &&
        productsLoadingStatus === 'idle' &&
        usersLoadingStatus === 'idle' ) ||
        (usersLoadingStatus === 'idle' && Object.keys(user).length === 0) ) {
        return children
    };

    const content = (
        <div className="container-fluid text-center d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
            <div className="row align-items-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )

    return content

}

export default Loader;