import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";


const toastsAdapter = createEntityAdapter();

const initialState = toastsAdapter.getInitialState({
  toasts: [],
});

const toastSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToast: (state, action) => {
      
      state.toasts.push(action.payload)
    },
    removeToast: (state, action) => {
      const newState = state.toasts.filter(t => t.id !== action.payload);

      state.toasts = newState;
      
    },
  },
});

const { reducer, actions } = toastSlice;

export const { addToast, removeToast } = actions;
export default reducer;
