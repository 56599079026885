import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";


const tabsAdapter = createEntityAdapter();


const tabsSlice = createSlice({
    name: 'tabs',
    initialState: tabsAdapter.getInitialState(),
    reducers: {
        addTab: tabsAdapter.addOne,
        removeTab: tabsAdapter.removeOne
    }
});

const { reducer, actions } = tabsSlice;

export const { selectAll } = tabsAdapter.getSelectors(state => state.tabs)

export default reducer;

export const { addTab, removeTab } =  actions;