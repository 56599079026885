import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"


const NotificationPage = () => {
    const {state} = useLocation();
    const navigate = useNavigate();

    const { message = '', status = '' } = state || {};
    useEffect(() => {
        if (!message || message.length === 0) {
            return navigate ('/');
        }
    })

    useEffect(() => {
        const timeout = setTimeout(() => {
            return  window.location.href = 'https://www.google.com';
        }, 10000)

        return () => {clearTimeout(timeout)}
    }, []);

    const handleClick = () => {
        return  window.location.href = 'https://www.google.com';
    }

    return (
        <div className="container d-flex align-items-center justify-content-center text-center mt-5">
            <div className="row align-items-center justify-content-center">
                <div className="w-100 mt-3"></div>
                <div className="col-auto">
                    <p className="h4">{status === 'ok' ? 'Успіх' : 'Помилка'}</p>
                    <p className="h5" style={{whiteSpace: `pre-wrap`}}>
                       {message}
                    </p>
                </div>
                <div className="w-100 mt-3"></div>
                <div className="col-auto">
                    <button onClick={() => handleClick()} className="btn btn-primary">Закрити сторінку</button>
                </div>
            </div>
        </div>
    )
}

export default NotificationPage;