import { combineReducers } from "@reduxjs/toolkit";
import licenses from './licensesSlice';
import appInfo from './appInfoSlice';
import customers from './customersSlice';
import products from './productsSlice';
import users from './usersSlice';
import tabs from './tabsSlice';
import toasts from './toastSlice'
import checkout from './checkoutSlice';

const rootReducer = combineReducers({
    licenses, appInfo, customers, products, users, tabs, toasts, checkout
});

export default rootReducer;