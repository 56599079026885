import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from '../../hooks/http.hook';
import { format } from "date-fns";
import { addToast } from "./toastSlice";

const licensesAdapter = createEntityAdapter();

const initialState = licensesAdapter.getInitialState({
    licenses: [],
    currentLicense: null,
    licensesLoadingStatus: 'idle',
    licenseLoadingStatus: 'idle'
});

export const fetchLicenses = createAsyncThunk(
    'licenses/fetchLicenses',
    async () => {
        const { request } = useHttp();
        const response = await request('licenses');
        return response;
    }
);

export const addLicense = createAsyncThunk(
    'licenses/addLicense',
    async ({license}) => {
        const { request } = useHttp();
        const response = await request(
            'license/add',
            'POST',
            JSON.stringify({
                license
            })
        );

        return response;
    }
);

export const updateLicense = createAsyncThunk(
    'licenses/updateLicense',
    async ({license}) => {
        const { request } = useHttp();

        const response = await request(
            'license/update',
            'POST',
            JSON.stringify({
                license
            })
        );

        return response;
    }
);

export const deleteLicense = createAsyncThunk(
    'licenses/deleteLicense',
    async ({id}) => {
        const { request } = useHttp();

        const response = await request(
            'license/delete',
            'POST',
            JSON.stringify({
                id
            })
        );

        return response;
    }
);

export const downloadLicense = (license) => {
    return async (dispatch) => {
        const { request } = useHttp();
        const { details, date_end, type, id } = license;

        let query = 'license/download/irro';
        let fileName = `${details}.bin`;

        if (type > 0 && type < 4) {
            query = 'license/download/cocktail';
            fileName = 'lic.bin';
        }

        try {
            const response = await request(
                query,
                'POST',
                JSON.stringify({
                    id,
                    details,
                    date_end: format(date_end, 'yyyy-MM-dd')
                }),
                {},
                'blob'
            );
            console.log(response);
            const contentType = response.headers.get('content-type');
            console.log(contentType);
            if (contentType.includes('json')) {
                
                const res = await response.json();
                dispatch(addToast({ id: Date.now(), message: res.message, status: res.status }));
            } else {
                const blob = await response.blob();

                if (blob.size !== 0) {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(addToast({ id: Date.now(), message: 'Ліцензію відправленно', status: 'ok' }));
                } else {
                    dispatch(addToast({ id: Date.now(), message: 'Помилка при формуванні файлу', status: 'error' }));
                }
            } 
        } catch (error) {
            console.log('Ошибка:', error);
            dispatch(addToast({ id: Date.now(), message: 'Произошла ошибка при загрузке лицензии.', status: 'error' }));
        }
        
    };
};

export const licenseRenewal = createAsyncThunk(
    'licenses/licenseRenewal',
    async ({data}) => {
        const { request } = useHttp();

        const response = await request(
            'license/renewal',
            'POST',
            JSON.stringify({
                ...data
            })
        );

        return response;
    }
)

const licensesSlice = createSlice({
    name: 'licenses',
    initialState,
    reducers: {
        update(state, action) {
            const { id } = action.payload;
            const index = state.licenses.findIndex(c => c.id === id.toString());

            if (index !== -1) {
                state.licenses[index] = { ...state.licenses[index], id: id.toString()};
            }
        },
        changeLicensesCustomer(state, action) {
            const { current, imported } = action.payload;
        
            const updated = state.licenses.map(item => {
                if (item && item.customer === imported) {
                    return {
                        ...item,
                        customer: current
                    };
                }
                return item;
            });
        
            return {
                ...state,
                licenses: updated
            };
        }, 
        setCurrentLicense(state, action) {
            state.currentLicense = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLicenses.pending, state => {
                state.licensesLoadingStatus = 'loading';
            })
            .addCase(fetchLicenses.fulfilled, (state, action) => {
                const { message = '', status = '', data = [] } = action.payload;

                state.licenses = data;
                state.licensesLoadingStatus = 'idle';
            })
            .addCase(fetchLicenses.rejected, state => {
                state.licensesLoadingStatus = 'error';
            })
            .addCase(addLicense.pending, state => {
                state.licenseLoadingStatus = 'loading';
            })
            .addCase(addLicense.fulfilled, (state, action) => {
                const { message = '', status = '', data = [] } = action.payload;

                if (status === 'ok' && data) {
                    state.licenses.push({ id: data.id.toString(), ...data});

                    state.licenseLoadingStatus = 'idle';
                }
            })
            .addCase(addLicense.rejected, state => {
                state.licenseLoadingStatus = 'error';
            })
            .addCase(updateLicense.pending, state => {
                state.licenseLoadingStatus = 'loading';
            })
            .addCase(updateLicense.fulfilled, (state, action) => {
                const { message = '', status = '', data = [] } = action.payload;

                if (status === 'ok' && data) {console.log(data)
                    const index = state.licenses.findIndex(c => c.id === data.id.toString());
                    state.licenses[index] = {...data}

                    state.licenseLoadingStatus = 'idle';
                }
            })
            .addCase(updateLicense.rejected, state => {
                state.licenseLoadingStatus = 'error';
            })
            .addCase(deleteLicense.pending, state => {
                state.licenseLoadingStatus = 'loading';
            })
            .addCase(deleteLicense.fulfilled, (state, action) => {
                const { message = '', status = '', data = [] } = action.payload;

                if (status === 'ok' && data) {
                    const licenses = state.licenses.filter(c => c.id !== data);

                    state.licenses = licenses;
                }

                state.licenseLoadingStatus = 'idle';
            })
            .addCase(deleteLicense.rejected, state => {
                state.licenseLoadingStatus = 'error';
            })
            .addCase(licenseRenewal.pending, state => {
                state.licenseLoadingStatus = 'loading';
            })
            .addCase(licenseRenewal.fulfilled, (state, action) => {
                const { message = '', status = '', data = [] } = action.payload;

                if (status === 'ok' && data) {

                    state.licenses.push({ id: data.id.toString(), ...data});
                }

                state.licenseLoadingStatus = 'idle';
            })
            .addCase(licenseRenewal.rejected, state => {
                state.licenseLoadingStatus = 'error';
            })
    } 
});

const { reducer, actions } = licensesSlice;

export default reducer;

export const { update, changeLicensesCustomer, setCurrentLicense } = actions;
