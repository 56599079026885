import { Formik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { fetchLiqpayData, fetchProductInfo, licenseActivate, licensePrepare} from "../redux/slices/checkoutSlice";

import Select from "../components/Select";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addToast } from "../redux/slices/toastSlice";


const LicenseCheckout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {details} = useParams();
    const product = useSelector(state => state.checkout.product) || [];
    const license = useSelector(state => state.checkout.license) || [];
    const liqpayData = useSelector(state => state.checkout.liqpay);
    const infoLoadingStatus = useSelector(state => state.checkout.infoLoadingStatus);
    const checkoutLoadingStatus = useSelector(state => state.checkout.checkoutLoadingStatus);
    const [periodConfirm, setPeriodConfirm] = useState(false);
    const [newPeriod, setNewPerion] = useState(false);
    console.log(checkoutLoadingStatus)
    useEffect(() => {
        if (product.length === 0 && !periodConfirm) {
            
            dispatch(fetchProductInfo({details}))
            .then(res => {
                const { status, message } = res.payload;

                if (status === 'error') {
                    return navigate('/notification', {state: {message, status}, replace: true});
                }
            });
        }
    }, [])
    
    useEffect(() => {
        if (periodConfirm && liqpayData.length === 0 && checkoutLoadingStatus === 'idle') {
                console.log('fetchLiqpayData')
                
        }
    }, [periodConfirm, liqpayData]);

    if (infoLoadingStatus === 'loading') {
        return (
            <div className="container-fluid text-center d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                <div className="row align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }
    let render;
    
    if ( license.length !== 0 && !periodConfirm) {
        render = <ContinuePayment rest={{ ...license, setPeriodConfirm, setNewPerion, details}}/>
    } else if (periodConfirm && !newPeriod && liqpayData.length !== 0) {
        return <LiqpayForm rest={{liqpayData, details}}/>
    } else {
        render = <PeriodForm 
                    rest={{ 
                        setPeriodConfirm,
                        setNewPerion,
                        newPeriod,
                        id: product.id, 
                        price: product.price, 
                        name: product.name,
                        details
                    }} 
                />
    }
    return (
        <div className="container text-center d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
            <div className="row align-items-center text-center  border border-5 p-2" style={{background: `#dbdbdb`}}>
                {render}
            </div>
        </div>
    );


}

const ContinuePayment = ({rest}) => {
    const dispatch = useDispatch();
    const { date_doc, date_end, price, setPeriodConfirm, setNewPerion, details } = rest;

    const payBtnHandle = () => {
        dispatch(fetchLiqpayData({details}))
        setPeriodConfirm(true)
    }

    const newLicenseBtnHandle = () => {
        setPeriodConfirm(true)
        setNewPerion(true)
    }

    return (
        <>
             <div className="col-12  align-self-center mb-5">
                <div>
                    <p className="h3">Оплата ліцензії</p>
                </div>
                <div>
                    <span className="fw-bold fs-5">У вас є сформована не оплачена ліцензія</span>
                    <span className="fw-5 ms-2"></span>
                </div>
                <div>
                    <span className="fw-bold fs-5">На період: </span>
                </div>
                <div>
                    <span className="fs-6 fw-5"><strong>Дата початку:</strong> {date_doc} </span>
                    <span className="fs-6 fw-5 ms-2"><strong>Дата закінчення:</strong> {date_end}</span>
                </div>
                <div>
                    <span className="fw-bold fs-5">Сума до оплати: </span>
                    <span className="fw-5 ms-2">{price} ₴</span>
                </div>
                <div className="mt-3">
                    <button onClick={() => payBtnHandle()} className="btn btn-primary me-2">Перейти до оплати</button>
                    <button onClick={() => newLicenseBtnHandle()} className="btn btn-primary">Сформувати нову ліцензію</button>
                </div>
            </div>
        </>
    )
}

const LiqpayForm = ({rest}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {liqpayData: {data, signature}, details} = rest;

    useEffect(() => {
        const loadLiqPayScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = "https://static.liqpay.ua/libjs/checkout.js";
                script.async = true;

                script.onload = () => resolve();
                script.onerror = () => reject(new Error("LiqPay script failed to load"));

                document.body.appendChild(script);
            });
        };

        loadLiqPayScript()
            .then(() => {
                window.LiqPayCheckout.init({
                    data: data,
                    signature: signature,
                    embedTo: '#liqpay_checkout',
                    language: 'ua',
                    mode: 'embed'
                }).on("liqpay.callback", function (data) {

                    const { status } = data;
                    if (status === 'success') {
                        dispatch(licenseActivate({details}))
                        .then(res => {
                            const {message, status, data} = res.payload
                            setTimeout(() => {
                                if (status === 'success') {
                                    return navigate('/notification', {state: {message, status}, replace: true});
                                } else {
                                    return navigate('/notification', {state: {message, status}, replace: true});
                                }
                            }, 5000)
                           
                        })
                    }
                }).on("liqpay.ready", function (data) {

                }).on("liqpay.close", function (data) {
                    window.LiqPayCheckout.close();
                });
            })
            .catch((error) => {
                console.error('Ошибка загрузки LiqPay скрипта:', error);
            });
    }, [data, signature]);

    return (
            <div id="liqpay_checkout"></div>
    )
}

const PeriodForm = ({rest}) => {
    const dispatch = useDispatch();
    const {setPeriodConfirm, setNewPerion, newPeriod, id, price, name, details} = rest;

    return (
        <>
            <div className="col-12  align-self-center mb-5">
                <div>
                    <p className="h3">Нова ліцензія</p>
                </div>
                <div>
                    <span className="fw-bold fs-5">Продукт: </span>
                    <span className="fw-5 ms-2">{name}</span>
                </div>
                <div>
                    <span className="fw-bold fs-5">Базова вартість: </span>
                    <span className="fw-5 ms-2">{price} (ціна за рік)</span>
                </div>
            </div>
            <div className="col-12 align-self-center">
                <Formik
                initialValues={{id: id || '',  period: '', startDateCurrent: newPeriod }}
                validationSchema={Yup.object().shape({
                    period: Yup.string()
                        .required('Оберіть період!'),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    dispatch(licensePrepare({data: {price, ...values}}))
                    .then(res => {
                        const {message, status, data} = res.payload;
                        if (status === 'ok') {
                            
                            dispatch(fetchLiqpayData({details}));
                            // dispatch(addToast({id: Date.now(), message, status}));
                            setPeriodConfirm(true);
                            setNewPerion(false);
                            setSubmitting(false);
                        }
                    })
                    setSubmitting(false);
                    // closeModal();
                    resetForm();
                }}
                >
                    {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                    

                        return (
                            <form onSubmit={handleSubmit} className="row justify-content-center align-items-center">
                                <div className="col-12 align-self-center w-auto">
                                    <Select
                                        label='Оберіть на який період Вам потрібна ліцензія'
                                        id="period"
                                        name='period'
                                    >
                                        <option value='' disabled>Оберіть період</option>
                                        <option value="1_month">1 місяць = {(price / 12).toFixed(0)} ₴</option>
                                        <option value="3_months">3 місяці = {((price / 12) * 3).toFixed(0)} ₴</option>
                                        <option value="6_months">6 місяців = {((price / 12) * 6).toFixed(0)} ₴</option>
                                        <option value="1_year">1 рік = {price} ₴</option>
                                    </Select>
                                </div>
                                
                                <div className="col-12 align-self-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Обробка...' : 'До оплати'}
                                    </button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </>
           
    )
}

export default LicenseCheckout