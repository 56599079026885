import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { removeTab, selectAll as tabsSelector } from "../redux/slices/tabsSlice";
import { ReactComponent as LogoutIcon } from '../assets/images/svg/logout.svg';
import { logoutUser } from "../redux/slices/usersSlice";
import { APP_VERSION } from "..";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const tabs = useSelector(tabsSelector);
    const user = useSelector(state => state.users.user);
    if (Object.keys(user).length === 0) return null;

    const navLinks = [
        { id: 1, protected: false, to: '/', label: 'Ліцензії' },
        { id: 2, protected: false, to: '/customers', label: 'Клієнти' },
        { id: 3, protected: true, to: '/users', label: 'Користувачі' },
        { id: 4, protected: false, to: '/directory', label: 'Довідник' },
    ];

    const renderNavLinks = navLinks
    .filter(link => {
        if (link.protected) {
            return user?.rigths === '1'
        }
        return true;
    })
        .map(link => (
        <li className="nav-item" key={link.id}>
            <NavLink
                to={link.to}
                className={({ isActive }) => 
                    isActive ? "nav-link active" : "nav-link"
                }
            >
                {link.label}
            </NavLink>
        </li>
    ));

    const handleTabClick = (id) => {
        return navigate(`/customer/${id}`)
    }

    const handleTabCloseClick = (id) => {
        const currentUser = pathname.split('/').pop();
        dispatch(removeTab(id));
        if (currentUser === id) {
            return navigate(`/customers`)
        }
    };
    
    const renderTabs = tabs.map(item => {
        const { id, name } = item;
    
        return (
            <li key={id} className="nav-item me-1" role="presentation">
                <button onClick={() => handleTabClick(id)} className="nav-link active position-relative pe-5">
                    {name}
                    <span 
                        onClick={(event) => {
                            event.stopPropagation();
                            handleTabCloseClick(id);
                        }} 
                        className="position-absolute top-50 translate-middle-y btn-close" 
                        style={{ right: `15px` }}
                    >
                        <span className="visually-hidden">Close tab</span>
                    </span>
                </button>
            </li>
        );
    });

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <nav className="navbar sticky-top navbar-expand-lg bg-dark border-bottom border-body" data-bs-theme="dark">
            <div className="container-fluid d-flex flex-column">
                <div className="collapse navbar-collapse w-100" id="navbarTogglerDemo01">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {renderNavLinks}
                    </ul>
                    <ul className="navbar-nav mb-2 mb-lg-0">
                        <li className="nav-item me-3">
                            <span style={{color: `white`, fontSize: 12}}>ver. {APP_VERSION}</span>
                        </li>
                        <li onClick={handleLogout} className="nav-item">
                            <LogoutIcon />
                        </li>
                    </ul>
                </div>
                {tabs.length !== 0 && (
                    <ul className="nav nav-pills w-100" id="pills-tab" role="tablist">
                        {renderTabs}
                    </ul>
                )}
            </div>
        </nav>
    );
};

export default Header;
