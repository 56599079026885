import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hooks/http.hook";

const appInfoAdapter = createEntityAdapter();

const initialState = appInfoAdapter.getInitialState({
    offices: [],
    goods: [],
    appInfoLoadingStatus: 'idle',
    currentGood: null,
    goodLoadingStatus: 'idle'
});

export const fetchAppInfo = createAsyncThunk(
    'appInfo/fetchAppInfo',
    async () => {
        const { request } = useHttp();

        const response = await request(`appInfo`);

        return response;
    }
);

export const addGood = createAsyncThunk(
    'appInfo/addGood',
    async ({good}) => {
        const { request } = useHttp();

        const response = await request(
            'good/add',
            'POST',
            JSON.stringify({
                good
            })
        );

        return {response, good};
    }
);

export const updateGood = createAsyncThunk(
    'appInfo/updateGood',
    async ({good}) => {
        const { request } = useHttp();

        const response = await request(
            'good/update',
            "POST",
            JSON.stringify({
                good
            })
        );

        return {response, good};
    }
)
export const deleteGood = createAsyncThunk(
    'appInfo/deleteGood',
    async ({id}) => {
        const { request } = useHttp();

        const response = await request(
            'good/delete',
            "POST",
            JSON.stringify({
                id
            })
        );

        return response;
    }
)

const appInfoSlice = createSlice({
    name: 'appInfo',
    initialState,
    reducers: {
        setCurrentGood(state, action) {
            state.currentGood = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppInfo.pending, state => { state.appInfoLoadingStatus = 'loading' })
            .addCase(fetchAppInfo.fulfilled, (state, actions) => {
                const {offices = [], goods = []} = actions.payload.data;

                state.offices = offices;
                state.goods = goods;

                state.appInfoLoadingStatus = 'idle'
            })
            .addCase(fetchAppInfo.rejected, state => {state.appInfoLoadingStatus = 'error'})
            .addCase(addGood.pending, state => { state.goodLoadingStatus = 'loading' })
            .addCase(addGood.fulfilled, (state, action) => {
                const {response, good} =  action.payload;
                const {status = 'error', message = '', data = []} = response;
                if (status === 'ok') {

                    state.goods.push({data, ...good})
                }
                state.goodLoadingStatus = 'idle'
            })
            .addCase(addGood.rejected, state => {state.goodLoadingStatus = 'error'})
            .addCase(updateGood.pending, state => { state.goodLoadingStatus = 'loading' })
            .addCase(updateGood.fulfilled, (state, action) => {
                const {response, good} =  action.payload;
                const {status = 'error', message = '', data = []} = response;
                if (status === 'ok') {
                    const index = state.goods.findIndex(g => g.id === good.id);

                    state.goods[index] = good;
                }
                state.goodLoadingStatus = 'idle'
            })
            .addCase(updateGood.rejected, state => {state.goodLoadingStatus = 'error'})
            .addCase(deleteGood.pending, state => { state.goodLoadingStatus = 'loading' })
            .addCase(deleteGood.fulfilled, (state, action) => {
                const {status = 'error', message = '', data = []} =  action.payload;;
                if (status === 'ok') {
                    const newState = state.goods.filter(g => g.id !== data);

                    state.goods = newState;
                }
                state.goodLoadingStatus = 'idle'
            })
            .addCase(deleteGood.rejected, state => {state.goodLoadingStatus = 'error'})
            .addDefaultCase(() => {})
    }
});

const { reducer, actions } = appInfoSlice;

export default reducer;

export const { setCurrentGood } = actions;