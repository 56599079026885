import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { format } from 'date-fns';
import { CreateLicense, DeleteLicense } from "./CustomerPage";
import { deleteLicense, downloadLicense, setCurrentLicense } from "../redux/slices/licensesSlice";

import Table from "../components/Table";


import {ReactComponent as TrashIcon} from '../assets/images/svg/trash.svg'
import { ReactComponent as DownloadIcon }   from '../assets/images/svg/download.svg';
import DeleteModal from "../components/Delete";



const LicensesPage = () => {
    const dispatch = useDispatch();
    const id = useSelector(state => state.licenses.currentLicense);
    const data = useSelector(state => state.licenses.licenses);
    const offices = useSelector(state => state.appInfo.offices);
    const goods = useSelector(state => state.appInfo.goods);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'index',
                header: '№',
                cell: ({ row }) => row.index + 1,
            },
            {
                accessorKey: 'date_doc',
                header: 'Дата з',
                meta: { filterVariant: 'text' },
                cell: ({ row }) => {
                    const date = format(new Date(row.getValue('date_doc')), 'dd.MM.yyyy');
                    return <span>{date}</span>;
                },
                filterFn: (row, columnId, filterValue) => {
                    const dateValue = row.getValue(columnId);
                    const formattedDate = format(new Date(dateValue), 'dd.MM.yyyy');
                    return formattedDate.includes(filterValue);
                },
            },
            {
                accessorKey: 'date_end',
                header: 'Дата по',
                meta: { filterVariant: 'text' },
                cell: ({ row }) => {
                    const date = format(new Date(row.getValue('date_end')), 'dd.MM.yyyy');
                    return <span>{date}</span>;
                },
                filterFn: (row, columnId, filterValue) => {
                    const dateValue = row.getValue(columnId);
                    const formattedDate = format(new Date(dateValue), 'dd.MM.yyyy');
                    return formattedDate.includes(filterValue);
                },
            },
            {
                accessorKey: 'name',
                header: 'Офіц.Назва',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'fullname',
                header: 'Клієнт',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'okpo',
                header: 'Код',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'fop',
                header: 'ФОП',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'url',
                header: 'УРЛ',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'adress',
                header: 'Адреса',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'phone',
                header: 'Телефон',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'details',
                header: 'Індетифікатор',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'type',
                header: 'Тип',
                meta: { filterVariant: 'text' },
                accessorFn: (row) => {
                    const good = goods.find(g => g.id === row?.type);
                    return good ? good.name : 'Нема';
                },
            },
            {
                id: 'office',
                header: 'Офіс',
                accessorFn: (row) => {
                    const office = offices.find(o => o.id === row?.office);
                    return office ? office.name : 'Нема';
                },
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'amount',
                header: 'Сума',
                meta: { filterVariant: 'text' },
            },
            {
                accessorKey: 'paid',
                header: 'Оплата',
                accessorFn: (row) => {
                    return row.paid === '1' ? 'Оплачено' : row.paid === '2' ? 'Тест' : 'Нема';
                },
            },
            {
                accessorKey: 'paid_v',
                header: 'Владік',
                accessorFn: (row) => {
                    return row.paid_v === '1' ? 'Оплачено' : 'Нема';
                },
            },
            {
                header: 'Дії',
                cell: ({ row }) => {
                    const { details, date_end, type, id } = row.original;
                    return (
                        <div className="row p-0 m-0 text-center align-items-center">
                            <div
                                onClick={() => {
                                    const license = {
                                        id,
                                        details,
                                        date_end,
                                        type: Number(type),
                                    };
                                    dispatch(downloadLicense(license));
                                }}
                                className="col p-0 m-1"
                            >
                                <DownloadIcon />
                            </div>
                            <div className="col w-25 p-0 m-1">
                                <TrashIcon onClick={() => { dispatch(setCurrentLicense(id)); setIsModalDeleteOpen(true); }} />
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    )

    return (
        <>
            <div className="row  m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити ліцензію</button>
                    </li>
                </ul>
            </div>
            <div className="row  m-0 p-0">
                <Table rest={{data, columns, filters: ['dateDocRange', 'dateEndRange'], onDoubleClick: setIsModalOpen}}/>
            </div>
            <CreateLicense rest={{isModalOpen, data, closeModal}}/>
            <DeleteModal rest={{itemType: 'license', action: () => deleteLicense({id}), isModalDeleteOpen, closeDeleteModal: () => setIsModalDeleteOpen(false)}}/>
        </>
    )
};

export default LicensesPage;