import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Alert from "../components/Alert";


const MainPage  = () => {

   

    return (
        <>
            <Header/>
            <div className="container-fluid position-relative">
                <Outlet/>
                <Alert />
            </div>
            
        </>
    )
};

export default MainPage;