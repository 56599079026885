import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import { Formik } from "formik";
import * as Yup from 'yup'
import md5 from '../helpers/md5';
import { useNavigate } from "react-router-dom";

import Modal from "../components/Modal";
import TextInput from "../components/TextInput";
import Select from "../components/Select";
import { addUser, deleteUser, setCurrentUser, udpateUser, update } from "../redux/slices/usersSlice";

import {ReactComponent as TrashIcon} from '../assets/images/svg/trash.svg'

import DeleteModal from "../components/Delete";
import { addToast } from "../redux/slices/toastSlice";


const UsersPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const id = useSelector(state => state.users.currentUser);
    const data = useSelector(state => state.users.users);
    const user = useSelector(state => state.users.user)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        if (user.rigths !== '1') {
            navigate('/'); 
        }
    }, [user, navigate]);

    const columns = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
            },
            {
                header: 'Ім`я',
                accessorKey: 'name',
            },
            {
                header: 'Права',
                accessorKey: 'rigths',
                cell: ({ row }) => {
                    const { rigths } = row.original;
                    return rigths === '1' ? 'Адміністратор' : 'Працівник';
                },
            },
            {
                header: 'Дії',
                id: 'actions',
                cell: ({ row }) => {
                    const { id } = row.original;
                    return (
                        <div className="row p-0 m-0 text-center align-items-center">
                            <div className="col w-25 p-0 m-1">
                                <TrashIcon onClick={() => {
                                    dispatch(setCurrentUser(id));
                                    setIsModalDeleteOpen(true);
                                }} />
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <div className="row  m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити користувача</button>
                    </li>
                </ul>
            </div>
            <div className="row  m-0 p-0">
                <Table rest={{data, columns, onDoubleClick: setIsModalOpen, pagination: false, search: false, filters: false}}/>
            </div>
            <UserModal rest={{isModalOpen, data, closeModal}}/>
            <DeleteModal rest={{itemType: 'user', action: () => deleteUser({id}), isModalDeleteOpen, closeDeleteModal: () => setIsModalDeleteOpen(false)}}/>
        </>
    )
}

const UserModal = ({rest}) => {
    const dispatch = useDispatch();
    const {isModalOpen, closeModal, data = [] } = rest;

    const customer = data.find(d => d.id === isModalOpen) || [];

    const {id = null, name = null, pass = null,  rigths = null} = customer;

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Новий користувач</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ name: name ?? '', password: '', confirmPassword: '', rigths: rigths ?? '' }}
                        validationSchema={id ? updateValidationSchema : validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                           
                            const updatedPassword = values.password ? md5(values.password) : pass;

                            if (id) {
                              dispatch(udpateUser({user: { id: id,  name: values.name, password: updatedPassword, rigths: values.rigths }}))
                              .then(res => {
                                const {status, message, data} = res.payload;
                                if (status === 'ok') {
                                  dispatch(update({ id: data, name: values.name, pass: updatedPassword, rigths: values.rigths}));
                                  dispatch(addToast({id: Date.now(), message, status}));
                                } else {
                                    dispatch(addToast({id: Date.now(), message, status}));
                                }
                                setSubmitting(false);
                                closeModal();
                              });
                            } else {
                                dispatch(addUser({user: {name: values.name, password: md5(values.password), rigths: values.rigths}}))
                                .then(res => {
                                    const {status, message, data} = res.payload;
                                    if (status === 'ok') {
                                        dispatch(update({id: data, name: values.name, pass: md5(values.password), rigths: values.rigths}))
                                        dispatch(addToast({id: Date.now(), message, status}));
                                    } else {
                                        dispatch(addToast({id: Date.now(), message, status}));
                                    }
                                    setSubmitting(false);
                                    closeModal();
                                })
                            }
                            resetForm();
                        }}
                    >
                        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                          

                            return (
                            <form onSubmit={handleSubmit} className="row">
                                <div className="col-12">
                                    <TextInput
                                        label='Ім`я'
                                        id='name'
                                        name='name'
                                    />
                                </div>
                                <div className="col-12">
                                    <TextInput
                                        label='Пароль'
                                        id='password'
                                        name='password'
                                    />
                                </div>
                                <div className="col-12">
                                    <TextInput
                                        label='Повторіть пароль'
                                        id='confirmPassword'
                                        name='confirmPassword'
                                    />
                                </div>
                                <div className="col-12">
                                    <Select
                                        label='Права'
                                        id='rigths'
                                        name='rigths'
                                    >
                                        <option value="" disabled>Оберіть права</option>
                                        <option value="1">Адміністратор</option>
                                        <option value="0">Працівник</option>
                                    </Select>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={closeModal}
                                    >
                                        Закрити
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-atuo me-2"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Збереження...' : 'Зберегти'}
                                    </button>
                                </div>
                            </form>
                            );
                        }}
                        </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    )
}

export const DeleteUser = ({rest}) => {
    const dispatch = useDispatch();
    const id = useSelector(state => state.users.currentUser);
    const handleClick = () => {
        dispatch(deleteUser({id}))
    }

    return (
        <div>
            <div className="modal fade" id="deleteProduct" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Видалення користувача</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Ви дійсно хочете видалити користувача?
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ні</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal"  onClick={handleClick}>Так</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
    .required("Ім'я обов'язкове")
    .min(2, "Ім'я повинно містити мінімум 2 символи"),
    
    password: Yup.string()
        .required("Пароль обов'язковий")
        .min(3, "Пароль повинен містити мінімум 3 символів"),
        
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Паролі повинні співпадати')
        .required("Підтвердження пароля обов'язкове"),
        
    rigths: Yup.string()
        .required("Необхідно обрати права"),
})
const updateValidationSchema = Yup.object().shape({
    name: Yup.string()
    .required("Ім'я обов'язкове")
    .min(2, "Ім'я повинно містити мінімум 2 символи"),
    
    password: Yup.string()
        .min(3, "Пароль повинен містити мінімум 3 символів"),
        
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Паролі повинні співпадати'),
        
    rigths: Yup.string()
        .required("Необхідно обрати права"),
})

export default UsersPage;