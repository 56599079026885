import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import {  useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { validationSchema, WarningMessage } from "./CustomerPage";
import { addCustomer, deleteCustomer, setCurrentCustomer, update } from "../redux/slices/customersSlice";
import { addTab, removeTab } from "../redux/slices/tabsSlice";

import TextInput from "../components/TextInput";
import Select from "../components/Select";

import {ReactComponent as TrashIcon} from '../assets/images/svg/trash.svg'
import Modal from "../components/Modal";
import DeleteModal from "../components/Delete";
import { addToast } from "../redux/slices/toastSlice";



const CustomersPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(state => state.customers.customers);
    const offices = useSelector(state => state.appInfo.offices);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const id = useSelector(state => state.customers.currentCustomer);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleDoubleClick = (id) => {
        dispatch(addTab({id, name: data.find(d => d.id === id)?.name ?? ''}));
        return navigate(`/customer/${id}`)
    }

    const columns = useMemo(() => [
        {
            id: 'id',
            header: 'ID',
            accessorKey: 'id',
            meta: { filterVariant: 'none' },
        },
        {
            id: 'name',
            header: 'Офіц.Назва',
            accessorKey: 'name',
            meta: { filterVariant: 'text' },
        },
        {
            id: 'fullname',
            header: 'Клієнт',
            accessorKey: 'fullname',
            meta: { filterVariant: 'text' },
        },
        {
            id: 'adress',
            header: 'Адреса',
            accessorKey: 'adress',
            meta: { filterVariant: 'text' },
        },
        {
            id: 'phone',
            header: 'Телефон',
            accessorKey: 'phone',
            meta: { filterVariant: 'text' },
        },
        {
            id: 'url',
            header: 'УРЛ',
            accessorKey: 'url',
            meta: { filterVariant: 'text' },
        },
        {
            id: 'products',
            header: 'Продукти',
            accessorKey: 'products',
            getIsVisible: () => false,
            enableColumnFilter: false, 
            enableSorting: false,
        },
        {
            id: 'office',
            header: 'Офіс',
            accessorFn: (row) => {
                const office = offices.find(o => o.id === row?.office);
                return office ? office.name : 'Нема';
            },
            meta: { filterVariant: 'text' },
        },
        {
            id: 'actions',
            header: 'Дії',
            cell: ({ row }) => {
                const { id } = row.original;
                return (
                    <TrashIcon
                        onClick={() => {
                            dispatch(setCurrentCustomer(id));
                            setIsModalDeleteOpen(true);
                        }}
                    />
                );
            },
        },
    ], []);

    return (
        <>
            <div className="row m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити клієнта</button>
                    </li>
                </ul>
            </div>
            <div className="row m-0 p-0">
                <Table rest={{data, columns, onDoubleClick: handleDoubleClick}}/>
            </div>
            <CreateCustomerModal rest={{ isModalOpen, closeModal }}/>
            <DeleteModal rest={{itemType: 'customer', action: () => deleteCustomer({id}), tabAction: () => removeTab(id), isModalDeleteOpen, closeDeleteModal: () => setIsModalDeleteOpen(false)}}/>
        </>
    )
};

const CreateCustomerModal = ({rest}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const offices = useSelector(state => state.appInfo.offices);
    
    const { isModalOpen, closeModal } = rest;

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Новий клієнт</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ companyName: '',  address: '', manager: '', phone: '', office: '', url: ''}}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            dispatch(addCustomer({customer: values}))
                            .then(data => {
                                if (data.payload.status === 'ok' && data.payload.data) {
                                    const id = data.payload.data

                                    dispatch(update({...values, id}));
                                    dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                    return navigate(`/customer/${id}`);
                                } else {
                                    dispatch(addToast({id: Date.now(), message: data.payload.message, status: data.payload.status}));
                                }
                                setSubmitting(false);
                            })
                            closeModal()
                        }}
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="row ">
                                <div className="col-6">
                                    <TextInput 
                                        label='Назва об`єкта'
                                        id="companyName"
                                        name='companyName'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Адреса'
                                        id="address"
                                        name='address'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Відповідальна особа'
                                        id="manager"
                                        name='manager'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Телефон'
                                        id="phone"
                                        name='phone'
                                    />
                                </div>
                                <div className="col-6">
                                    <Select
                                        label='Офіс'
                                        id="office"
                                        name='office'
                                    >
                                        <option value="0">Немає</option>
                                        {offices.map(item => {
                                            const {id, name, type} = item;

                                            return (
                                                <option key={id} value={type}>{name}</option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='URL'
                                        id="url"
                                        name='url'
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary me-2" onClick={closeModal}>Закрити</button>
                                    <button  type="submit" className="btn btn-primary w-atuo me-2" disabled={isSubmitting}>
                                        {isSubmitting ? "Збереження..." : "Зберегти"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    )
}

export default CustomersPage;