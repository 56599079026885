import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { removeToast } from '../redux/slices/toastSlice';
import { useDispatch, useSelector } from 'react-redux';

const Alert = () => {
  const toasts = useSelector(state => state.toasts.toasts ?? []);
  const dispatch = useDispatch();

  useEffect(() => {
    const timers = toasts.map((toast) =>
      setTimeout(() => {
        dispatch(removeToast(toast.id));
      }, toast.duration || 5000) 
    );

    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [toasts, dispatch]);

  return (
    <div className="alert-container">
      {toasts.map((toast) => (
        <motion.div
          key={toast.id}
          className={`alert alert-${toast.status === 'ok' ? 'success' : 'danger'} alert-dismissible fade show`}
          role="alert"
          initial={{ opacity: 0, translateY: -50 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, translateY: -50 }}
          transition={{ duration: 0.3 }}
        >
              <div>{toast.message}</div>
              <button type="button" class="btn-close" onClick={() => dispatch(removeToast(toast.id))}></button>
        </motion.div>
      ))}
    </div>
  );
};

export default Alert;