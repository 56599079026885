import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hooks/http.hook";


const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState({
    users: [],
    user: JSON.parse(localStorage.getItem('irro_user')) || {},
    currentUser: null,
    usersLoadingStatus: 'idle',
    userLoadingStatus: 'idle',
});

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async () => {
        const { request } = useHttp();

        const response = await request('users')

        return response;
    }
);

export const loginUser = createAsyncThunk(
    'users/loginUser',
    async ({user}) => {
        const { request } = useHttp();

        const response = await request(
            'login',
            'POST',
            JSON.stringify({
                user
            })
        );

        return response;
    }
);

export const logoutUser = createAsyncThunk(
    'users/logoutUser',
    async () => {
        const { request } = useHttp();

        const response = await request('logout');

        return response
    }

    
);

export const addUser = createAsyncThunk(
    'users/addUser',
    async ({user}) => {
        const { request } = useHttp();

        const response = await request(
            'user/add',
            'POST',
            JSON.stringify({
                user
            })
        )

        return response;
    }  
);

export const udpateUser = createAsyncThunk(
    'users/udpateUser',
    async ({user}) => {
        const { request } = useHttp();

        const response = await request(
            'user/update',
            'POST',
            JSON.stringify({
                user
            })
        );

        return response;
    }
);

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async ({id}) => {
        const { request } = useHttp();

        const response = await request(
            'user/delete',
            'POST',
            JSON.stringify({
                id
            })
        );

        return response;
    }
)

export const importLicenses = createAsyncThunk(
    'users/importLicenses',
    async ({data}) => {
        const { request } = useHttp();

        const response = await request(
            'user/import',
            "POST",
            JSON.stringify({
                ...data
            })
        );

        return response;
    }

)

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        update(state, action) {
            const { id, ...data } = action.payload;
            const index = state.users.findIndex(c => c.id === id.toString());

            if (index !== -1) {
                state.users[index] = { ...data, id: id.toString()};
            }
        },
        setCurrentUser(state, action) {
            state.currentUser = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, state => {state.usersLoadingStatus = 'loading'})
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const {status, message, data} = action.payload;
                if (status === 'ok') {
                    state.users = data;

                    state.usersLoadingStatus = 'idle'
                }
            })
            .addCase(fetchUsers.rejected, state => {state.usersLoadingStatus = 'error'})
            .addCase(loginUser.pending, state => {state.userLoadingStatus = 'loading'})
            .addCase(loginUser.fulfilled, (state, action) => {
                const {status, message, data} = action.payload;
                if (status === 'ok') {
                    state.user = {...data};

                    localStorage.setItem('irro_user', JSON.stringify({...data}));

                    state.userLoadingStatus = 'idle';
                }
            })
            .addCase(loginUser.rejected, state => {state.userLoadingStatus = 'error'})
            .addCase(logoutUser.pending, state => {state.userLoadingStatus = 'loading'})
            .addCase(logoutUser.fulfilled, (state, action) => {
                const {status, message, data} = action.payload;
                if (status === 'ok') {
                    state.user = {};
                    localStorage.removeItem('irro_user');
                    state.userLoadingStatus = 'idle';
                }
            })
            .addCase(logoutUser.rejected, state => {state.userLoadingStatus = 'error'})
            .addCase(addUser.pending, state => {state.userLoadingStatus = 'loading'})
            .addCase(addUser.fulfilled, (state, action) => {
                const {status, message, data } = action.payload;
                if (status === 'ok') {
                    state.users.push({ id: data.toString(), ...data});

                    state.userLoadingStatus = 'idle'
                }
            })
            .addCase(addUser.rejected, state => {state.userLoadingStatus = 'error'})
            .addCase(udpateUser.pending, state => {state.userLoadingStatus = 'loading'})
            .addCase(udpateUser.fulfilled, (state, action) => {
                const {status, message, data } = action.payload;
                if (status === 'ok') {
                    const index = state.users.findIndex(u => u.id === data.toString());
                    state.users[index] = {...state.users[index]}

                    state.userLoadingStatus = 'idle'
                }
            })
            .addCase(udpateUser.rejected, state => {state.userLoadingStatus = 'error'})
            .addCase(deleteUser.pending, state => {state.userLoadingStatus = 'loading'})
            .addCase(deleteUser.fulfilled, (state, action) => {
                const {status, message, data } = action.payload;
                if (status === 'ok') {
                    if (data === state.user.id) {
                        state.user = {}
                        localStorage.removeItem('irro_user');
                    } else {
                        const newState = state.users.filter(u => u.id !== data);
                        state.users = newState;
                    }
                    state.userLoadingStatus = 'idle'
                }
            })
            .addCase(deleteUser.rejected, state => {state.userLoadingStatus = 'error'})
    }
});

const { reducer, actions } = usersSlice;

export default reducer;

export const { update, setCurrentUser } = actions;