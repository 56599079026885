import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

//pages
import MainPage from "../pages/MainPage";
import Loader from "../hoc/Loader";
import LicensesPage from "../pages/LicensesPage";
import CustomersPage from "../pages/CustomersPage";
import CustomerPage from "../pages/CustomerPage";
import LoginPage from "../pages/LoginPage";
import PrivateRoute from "./PrivateRoute";
import UsersPage from "../pages/UsersPage";
import DirectoryPage from "../pages/DirectoryPage";
import LicenseCheckout from "../pages/LicenseCheckout";
import NotificationPage from "../pages/NotificationPage";

const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={
            <Loader>
                <MainPage/>
            </Loader>
        }
    >
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/notification" element={<NotificationPage/>}/>
            <Route path="/license-checkout/:details" element={<LicenseCheckout/>}/>
            <Route index element={<PrivateRoute element={<LicensesPage />} />} />
            <Route path="/customers" element={<PrivateRoute element={<CustomersPage />} />} />
            <Route path="/customer/:customerId" element={<PrivateRoute element={<CustomerPage />} />} />
            <Route path="/users" element={ <PrivateRoute element={<UsersPage/>}/> } />
            <Route path="/directory" element={<PrivateRoute element={<DirectoryPage/>}/>}/>
    </Route>
));

function AppRoutes() {
    return <RouterProvider router={router}/>
}

export default AppRoutes;