import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hooks/http.hook";

const checkoutAdapter = createEntityAdapter();

export const fetchProductInfo = createAsyncThunk(
    'checkout/fetchProductInfo',
    async ({details}) => {
        const { request } = useHttp();
        
        const response = await request(
            'checkout/get-info',
            'POST',
            JSON.stringify({details})
        );
        return response;
    }
);

export const licensePrepare = createAsyncThunk(
    'checkout/licensePrepare',
    async ({data}) => {
        const { request } = useHttp();

        const response = await request(
            'checkout/license-prepare',
            'POST',
            JSON.stringify({
                ...data
            })
        );

        return response;
    }
)

export const fetchLiqpayData = createAsyncThunk(
    'checkout/fetchLiqpayData',
    async ({details}) => {
        const { request } = useHttp();

        const response = await request(
            'checkout/get-liqpay-data',
            'POST',
            JSON.stringify({details})
        )

        return response;
    }
)

export const licenseActivate = createAsyncThunk(
    'checkout/licenseActivate',
    async ({details}) => {
        const { request } = useHttp();

        const response = await request(
            'checkout/license-activate',
            'POST',
            JSON.stringify({details})
        );

        return response;
    }
)

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: checkoutAdapter.getInitialState({
        checkoutLoadingStatus: 'idle',
        infoLoadingStatus: 'idle',
        product: [],
        license: [],
        liqpay: [],
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductInfo.pending, state => {state.infoLoadingStatus = 'loading'})
            .addCase(fetchProductInfo.fulfilled, (state, action) => {
                const {status, message, data: { product = [], license = [] }} = action.payload;
                if (status === 'ok') {
                    state.product = product; 
                    state.license = license; 
                    state.infoLoadingStatus = 'idle'
                } 
            })
            .addCase(fetchProductInfo.rejected, state => {state.infoLoadingStatus = 'error'})
            .addCase(licensePrepare.pending, state => {state.checkoutLoadingStatus = 'loading'})
            .addCase(licensePrepare.fulfilled, (state, action) => {
                const {status, message, data} = action.payload;
                // if (status === 'ok') {
                //     state.product = data; 
                //     
                // }
                state.checkoutLoadingStatus = 'idle'
            })
            .addCase(licensePrepare.rejected, state => {state.checkoutLoadingStatus = 'error'})
            .addCase(fetchLiqpayData.pending, state => {state.checkoutLoadingStatus = 'loading'})
            .addCase(fetchLiqpayData.fulfilled, (state, action) => {
                const {status, message, data} = action.payload;
                if (status === 'ok') {
                    state.liqpay = data; 
                    state.checkoutLoadingStatus = 'idle'
                } 
            })
            .addCase(fetchLiqpayData.rejected, state => {state.checkoutLoadingStatus = 'error'})
    }
})

const { reducer } = checkoutSlice;

export default reducer;