import { useDispatch, useSelector } from "react-redux";
import Select from "../components/Select";
import * as Yup from 'yup'
import { Formik } from "formik";
import TextInput from "../components/TextInput";
import { loginUser } from "../redux/slices/usersSlice";
import md5 from '../helpers/md5';
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const users = useSelector(state => state.users.users) || [];

    return (
        <Formik
            initialValues={{ id: '', password: ''}}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setFieldError  }) => {
                setSubmitting(true);
                
                dispatch(loginUser({user: {id: values.id, password: md5(values.password)}}))
                .then(data => {
                    const { status, message} = data.payload;
                    if (status === 'error') {
                        setSubmitting(false);
                        setFieldError("password", message);
                    } else {
                        setSubmitting(false);
                        return navigate('/');
                    }
                }) 
            }}
        >
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
            

                return (
                    <div className="d-flex justify-content-center align-items-center min-vh-100 ">
                        <form onSubmit={handleSubmit} className="row  w-25">
                            <div className="col-12">
                                <Select
                                    label='Користувачі'
                                    id="id"
                                    name='id'
                                >
                                    <option value='' disabled>Оберіть користувача</option>
                                    {users.length !== 0 &&
                                        users.map(item => {
                                            const { id, name, rigths} = item;

                                            return (
                                                <option value={id}>{name}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            <div className="col-12">
                                <TextInput
                                    label='Пароль'
                                    id="password"
                                    name='password'
                                    type="password"
                                    placeholder='Ведіть пароль'
                                />
                            </div>
                            <div className="col align-self-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Вхід...' : 'Увійти'}
                                </button>
                            </div>
                        </form>
                    </div>
                );
            }}
        </Formik>
    )
};

export const validationSchema = Yup.object().shape({
    id: Yup.string()
        .required('Оберіть користувача!'),
    password: Yup.string()
        .required('Заповніть пароль')
})

export default LoginPage;