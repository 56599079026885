import Select from "react-select";
import { useField, useFormikContext } from "formik";
import { useSelector } from "react-redux";

const formatOptions = (data, customer = null) => {
    return data
        .filter(i => i.id !== customer)
        .map(item => {
            const formattedLabel = [
                item.name,
                item.fullname,
                item.address,
                item.phone,
                item.okpo,
                item.fop,
            ].filter(Boolean).join(' - ');  

            return {
                value: item.id,
                label: formattedLabel
        };
    });
};

const fieldsToSearch = ['label', 'value'];

const formatOptionLabel = ({ label }) => (
    <div className="custom-option">
        <span>{label}</span>
    </div>
);

const customFilterOption = (option, inputValue) => {
    const searchTerm = typeof inputValue === 'string' ? inputValue.toLowerCase() : '';

    return fieldsToSearch.some(field => {
        let fieldValue = '';

        if (option.data[field]) {
            if (typeof option.data[field] === 'object' && option.data[field]?.name) {
                fieldValue = option.data[field].name.toLowerCase();
            } else if (typeof option.data[field] === 'string') {
                fieldValue = option.data[field].toLowerCase();
            }
        }

        return fieldValue.includes(searchTerm);
    });
};

const CustomersSelect = ({ label, ...props }) => {
    const customers = useSelector(state => state.customers.customers) || [];
    const [field, meta, helpers] = useField(props);
    const { setFieldValue } = useFormikContext();

    const options = formatOptions(customers, props.customerId ?? null);

    const handleChange = (selectedOption) => {
        setFieldValue(props.name, selectedOption ? selectedOption.value : ''); 
        setFieldValue('details', ''); 
        setFieldValue('type', ''); 
        setFieldValue('price', '');
    };

    return (
        <div className="mb-3">
            <label className="form-label fs-6 fw-bold" htmlFor={props.name}>
                {label}
            </label>
            <Select
                id={props.name}
                options={options}
                value={options.find(option => option.value === field.value) || null}
                onChange={handleChange}
                filterOption={customFilterOption}
                formatOptionLabel={formatOptionLabel}
                placeholder="Пошук клієнта"
                noOptionsMessage={() => "Клієнта не знайдено"}
                onBlur={() => helpers.setTouched(true)}
                {...props}
            />
            {meta.touched && meta.error ? (
                <div className="error-message">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default CustomersSelect;
